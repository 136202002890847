import App from "./App.vue";
//import Vouchers from './components/Vouchers/Vouchers.vue'
//import Payments from './components/Payments/Payments.vue'
import Login from "./components/Auth/Login.vue";
import ChooseStore from "./components/Auth/ChooseStore.vue";
import ChooseStation from "./components/Auth/ChooseStation.vue";
import { createStore } from "vuex";
import cart from "./store/cart.js";
import token from "./store/token.js";
import { createApp } from "vue";
import { api } from "@/helpers/api";
import Notifications from "@kyvg/vue3-notification";
import * as Sentry from "@sentry/vue";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPhone,
  faEdit,
  faPlus,
  faMinus,
  faSave,
  faTrash,
  faBars,
  faSort,
  faMinusCircle,
  faPlusCircle,
  faChevronDown,
  faChevronUp,
  faGripHorizontal,
  faGripLines,
  faCircleXmark,
  faSquareCaretDown,
  faPencil,
  faGear,
  faCamera,
  faBackward,
  faCodeCompare,
  faShoppingCart,
  faShop,
  faPenToSquare,
  faMoneyBillTrendUp,
  faTag,
  faRightFromBracket,
  faPlugCirclePlus,
  faMoneyCheckDollar,
  faFileInvoiceDollar,
  faUser,
  faUserAstronaut,
  faGift,
  faEnvelope,
  faCalendarDays,
  faArrowRight,
  faArrowLeft,
  faCircleCheck,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
library.add(
  faPhone,
  faEdit,
  faPlus,
  faMinus,
  faSave,
  faTrash,
  faBars,
  faSort,
  faMinusCircle,
  faPlusCircle,
  faChevronDown,
  faChevronUp,
  faGripHorizontal,
  faGripLines,
  faCircleXmark,
  faSquareCaretDown,
  faPencil,
  faGear,
  faCamera,
  faBackward,
  faCodeCompare,
  faShoppingCart,
  faShop,
  faPenToSquare,
  faMoneyBillTrendUp,
  faTag,
  faRightFromBracket,
  faPlugCirclePlus,
  faMoneyCheckDollar,
  faFileInvoiceDollar,
  faUser,
  faUserAstronaut,
  faGift,
  faEnvelope,
  faCalendarDays,
  faArrowRight,
  faArrowLeft,
  faCircleCheck,
  faXmark,
);

import { createRouter, createWebHistory } from "vue-router";
//import loggingPlugin from "@/plugins/loggingPlugin";
//import { createLogger } from 'vuex'

//import Datepicker from '@vuepic/vue-datepicker';
//import '@vuepic/vue-datepicker/dist/main.css'
const Orders = () => import("./components/Orders/Orders.vue");
const Store = () => import("./components/Store/Store.vue");
const Cart = () => import("./components/Store/Cart.vue");
const Totals = () => import("./components/Totals/Totals.vue");
const Settings = () => import("./components/Settings/Settings.vue");
const Categories = () => import("./components/Categories/Categories.vue");
const Category = () => import("./components/Categories/Category.vue");
const Modifiers = () => import("./components/Modifiers/Modifiers.vue");
const ModifierSet = () => import("./components/Modifiers/ModifierSet.vue");
const Accounts = () => import("./components/Accounts/Accounts.vue");
const Vouchers = () => import("./components/Vouchers/Vouchers.vue");
const PasswordReset = () => import("./components/Auth/PasswordReset.vue");

const store = createStore({
  modules: { token: token, cart: cart },
  //plugins: [createLogger()];
});

//const Cart = () => import('./components/Store/Cart.vue');

const router = new createRouter({
  //history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(),
  routes: [
    { path: "/", name: "Home", component: Store, meta: { requiresAuth: true } },
    { path: "/login", name: "Login", component: Login },
    { path: "/choose", name: "Choose", component: ChooseStore, meta: { requiresAuth: true } },
    { path: "/station", name: "Station", component: ChooseStation, meta: { requiresAuth: true } },
    { path: "/cart", component: Cart, meta: { requiresAuth: true } },
    { path: "/store", component: Store, meta: { requiresAuth: true } },
    { path: "/store/:category/:product", component: Store, meta: { requiresAuth: false } },
    { path: "/web/:id", component: Store, meta: { requiresAuth: false } },
    { path: "/web", component: Store, meta: { requiresAuth: false } },
    { path: "/web/:category/:product", component: Store, meta: { requiresAuth: false } },
    { path: "/web/:id/:category/:product", component: Store, meta: { requiresAuth: false } },
    { path: "/modifiers", component: Modifiers, meta: { requiresAuth: true } },
    { path: "/modifiers/:set", component: ModifierSet, meta: { requiresAuth: true } },
    { path: "/products", component: Categories, meta: { requiresAuth: true } },
    { path: "/products/:category", component: Category, meta: { requiresAuth: true } },
    { path: "/products/:category/:product", component: Category, meta: { requiresAuth: true } },
    { path: "/orders", component: Orders, meta: { requiresAuth: true } },
    { path: "/accounts", component: Accounts, meta: { requiresAuth: true } },
    { path: "/accounts/:account", component: Accounts, meta: { requiresAuth: true } },
    { path: "/vouchers", component: Vouchers, meta: { requiresAuth: true } },
    { path: "/vouchers/:voucher", component: Vouchers, meta: { requiresAuth: true } },
    //{path: '/payments', component: Payments, meta: {requiresAuth: true}},
    { path: "/settings", component: Settings, meta: { requiresAuth: true } },
    { path: "/totals", component: Totals, meta: { requiresAuth: true } },
    { path: "/reset/:token", name: "PasswordReset", component: PasswordReset, meta: { requiresAuth: false } },

    //{path: '/discounts', component: Discounts, meta: {requiresAuth: true}},
  ],
});

export default store;

const isAuthenticated = async () => {
  const stations = JSON.parse(window.localStorage.getItem("stations"));
  let auth = false;

  if (stations && stations.length > 0) {
    auth = true;
  }
  return store.getters.token && auth ? true : false;
};

const isWebStore = async (id) => {
  await store.commit("setWebStoreMutation", true);
  await api.getSite(id);
};

router.beforeEach(async (to, from, next) => {
  let url = window.location.host.split(".");

  if (to.params.id) {
   await isWebStore(to.params.id);
    return next();
  } else if (url[1] == "web") {
    to.params.id = url[0]
    await isWebStore(url[0]);
    return next();
  } else {
    await store.commit("setWebStoreMutation", false);
    //api.loadTheme(JSON.parse(window.localStorage.getItem("theme")));
  }

  const authenticated = await isAuthenticated();
  if (to.meta.requiresAuth) {
    if (authenticated) return next();

    return next({ name: "Login" });
  }

  return next();
});

const app = createApp(App);

Sentry.init({
  app,
    dsn: "https://9b3a6f84bb430c86b56898fca7497078@o4505633539489792.ingest.sentry.io/4505633543880704",
    integrations: [
	new Sentry.Replay(),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.05, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

/***Sentry.init({
  app,
  dsn: "https://9b3a6f84bb430c86b56898fca7497078@o4505633539489792.ingest.sentry.io/4505633543880704",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  //tracePropagationTargets: ["localhost", "api.beamy.nz",/^\//],
  tracePropagationTargets: ["localhost",/^\//],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});***/


app.use(Notifications).use(router).use(store).component("font-awesome-icon", FontAwesomeIcon).mount("#app");
//app.component('Datepicker', Datepicker);
//app.use(loggingPlugin);
